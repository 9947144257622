// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
// Variables
@import 'variables';
$theme-colors: ( "darkBlue": $darkBlue,
"main": $main,
);
// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
$fa-font-path: "../webfonts";

